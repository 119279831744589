const SmartTag = require('./SmartTag');

class SmartProductTag extends SmartTag {
  constructor({ uid, attribute }) {
    super({ uid, attribute });
  }

  type() {
    return 'smart-product-tag';
  }

  isDefaultValueCannotBeSet() {
    return false;
  }

  _removeDefaultValue() {
    return true;
  }

  getContent() {
    const splitted = this.attribute.slice(0, -2).slice(2).split(':');
    splitted.shift();
    return splitted.join(':');
  }

  getAttributeWithDefaultValue() {
    return this.attribute
      .slice(0, this.attribute.length - 2)
      .split(':')[0]
      .concat(`:${this.defaultValue}`)
      .concat(']]');
  }
}

module.exports = SmartProductTag;
