const SmartTag = require('./SmartTag');

class General extends SmartTag {
  constructor({ uid, label, attribute, defaultValue }, translator) {
    super({ uid, label, attribute, defaultValue }, translator);
    this.translator = translator;
  }

  type() {
    return 'general';
  }

  isDefaultValueCannotBeSet() {
    // skip default value set
    return this.attribute.includes('cart:');
  }

  getContent() {
    return this.translator.t(`smartTags.tags.${this.label}`);
  }

  _removeDefaultValue() {
    const hasDefault = this.hasDefaultValue();
    if (hasDefault) {
      this.setAttribute(this.attribute.split('|')[0].concat(']]'));
    }
  }

  hasDefaultValue() {
    const splittedByPipe = this.getAttribute().split('|');
    return splittedByPipe.length > 1 ? splittedByPipe[1].split(']]')[0] : false;
  }

  getAttributeWithDefaultValue() {
    return this.attribute
      .slice(0, this.attribute.length - 2)
      .concat(this.defaultValue ? `|${this.defaultValue}` : '')
      .concat(']]');
  }

  buildDefaultValue(value) {
    const defaultValue = this.hasDefaultValue();
    if (defaultValue) return this.replaceDefaultValue(defaultValue, value);

    return this.getAttribute().split(']]')[0].concat(`|${value}`).concat(']]');
  }
}

module.exports = General;
