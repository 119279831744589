<template lang="pug">
om-modal(
  name="smart-tag-modal"
  :width="400"
  color="light"
  @beforeOpen="beforeOpen"
  @beforeClose="beforeClose"
)
  template(slot="modal-header")
    .row
      .col
        .font-weight-bold.font-size-1--25 {{ title }}
    .brand-modal-action-icon
      span.cursor-pointer(@click="cancel" title="close" aria-hidden="true")
        close-icon(:width="12" :height="12")
  template(slot="modal-body")
    .d-flex.flex-column
      .col
        .row.align-items-center
          component(:is="type" :defaultValue.sync="defaultValue" :label="label" @enter="enter")
  template(slot="modal-footer")
    .d-flex.justify-content-end
      om-button.mr-3(ghost @click="cancel") {{ $t('cancel') }}
      om-button(primary @click="action" :disabled="disabled") {{ actionButtonText }}
</template>
<script>
  import SmartTagFactory from '@om/smart-tags/src/SmartTagFactory';
  import SmartTagParser from '@om/smart-tags/src/Parser';
  import { mapState, mapGetters } from 'vuex';
  import { generateRandomStr } from '@/util';
  import {
    trackSelection,
    trackSetDefaultValue,
    trackModifyDefaultValue,
    trackCancelButton,
    trackOpenExistingSmartTag,
  } from '@/services/userInteractionTracker/smartTagTracker';
  import { DATA_ATTRIBUTES } from '@om/smart-tags/src/helper';
  import { workspaceWindow } from './helper';
  import * as Types from './types';

  export default {
    components: {
      ...Types,
    },
    data() {
      return {
        isEditMode: false,
        smartTagInstance: null,
        label: '',
        defaultValue: '',
        isCartValueCountdown: false,
        quillRef: null,
        insertedSmartTagBlot: null,
      };
    },
    computed: {
      ...mapState(['selectedElement', 'showFormManager']),
      ...mapGetters(['databaseId']),
      type() {
        if (this.isCartValueCountdown) {
          return 'cart-value-countdown';
        }

        return 'general';
      },
      title() {
        return this.$t(`smartTags.modal.${this.isEditMode ? 'edit' : 'new'}.${this.type}`);
      },
      actionButtonText() {
        return this.isEditMode ? this.$t('done') : this.$t('save');
      },
      disabled() {
        if (this.isCartValueCountdown) {
          return !this.defaultValue || parseInt(this.defaultValue, 10) <= 0;
        }

        return false;
      },
    },
    mounted() {
      this.$bus.$on('create-smart-tag', (option) => {
        this.smartTagInstance = new SmartTagFactory({
          uid: generateRandomStr(7),
          attribute: option,
        }).instance;

        const workspace = workspaceWindow();
        this.quillRef = workspace.editorRef;

        const variableName = this.getPPOVariableNameFromOption(option);
        this.setSmartTag(variableName);
      });
    },
    methods: {
      getPPOVariableNameFromOption(option) {
        const match = option.match(/\[\[smart_product_tag:(.+?)\]\]/);
        if (match && match.length > 1) {
          return match[1];
        }
      },
      cancel() {
        trackCancelButton({
          databaseId: this.databaseId,
          elementId: this.selectedElement.uid,
        });
        this.hideModal();
      },
      hideModal() {
        this.$modal.hide('smart-tag-modal');
      },
      enter() {
        this.action();
      },
      action() {
        if (this.isEditMode) {
          this.modify();
        } else {
          this.save();
        }
      },
      save() {
        this.setSmartTag(this.defaultValue);
        trackSetDefaultValue(this.smartTagInstance, {
          databaseId: this.databaseId,
          elementId: this.selectedElement.uid,
        });
        this.hideModal();
      },
      modify() {
        this.smartTagInstance.setDefaultValue(this.defaultValue);
        this.insertedSmartTagBlot.format(
          DATA_ATTRIBUTES.TAG,
          this.smartTagInstance.getAttributeWithDefaultValue(),
        );
        trackModifyDefaultValue(this.smartTagInstance, {
          databaseId: this.databaseId,
          elementId: this.selectedElement.uid,
        });
        this.hideModal();
      },
      createInstance(label, optionValue) {
        this.smartTagInstance = new SmartTagFactory(
          {
            uid: generateRandomStr(7),
            label,
            attribute: optionValue,
            defaultValue: this.defaultValue,
          },
          this.$i18n,
        ).instance;
        trackSelection(this.smartTagInstance, {
          databaseId: this.databaseId,
          elementId: this.selectedElement.uid,
        });
      },
      restoreOriginalSelection(index, range) {
        this.quillRef.setSelection(index, range);
      },
      isSelectionInLastPos(index, length) {
        return index === length - 1;
      },
      setSmartTag(defaultValue) {
        this.smartTagInstance.setDefaultValue(defaultValue);
        const { index } = this.quillRef.getSelection();
        const length = this.quillRef.getLength();
        const isLastPosition = this.isSelectionInLastPos(index, length);
        this.quillRef.insertEmbed(
          isLastPosition ? index - 1 : index,
          'smart-tag',
          this.smartTagInstance,
        );
      },
      createHTMLInstance(smartTagElement) {
        this.smartTagInstance = SmartTagParser.parse(smartTagElement, this.$i18n);
      },
      getProperties(params) {
        if (this.isEditMode) {
          const { smartTagElement } = params;
          this.createHTMLInstance(smartTagElement);

          if (this.smartTagInstance.isDefaultValueCannotBeSet()) {
            this.$nextTick(() => {
              this.hideModal();
            });
            return;
          }

          this.label = this.smartTagInstance.getContent();
          this.defaultValue = this.smartTagInstance.getDefaultValue();
          this.isCartValueCountdown = this.smartTagInstance.type() === 'cart-value-countdown';
          return;
        }

        const {
          currentSelection: { index, range },
          optionValue,
          label,
        } = params;
        this.label = this.$t(`smartTags.tags.${label}`);
        this.createInstance(label, optionValue);
        this.isCartValueCountdown = this.smartTagInstance.type() === 'cart-value-countdown';

        this.restoreOriginalSelection(index, range || 0);

        if (this.smartTagInstance.isDefaultValueCannotBeSet()) {
          this.setSmartTag();
          this.$nextTick(() => {
            this.hideModal();
          });
        }
      },
      async beforeOpen(event) {
        const workspace = workspaceWindow();
        this.quillRef = workspace.editorRef;

        if (event.params.hasOwnProperty('edit')) {
          this.isEditMode = true;
          this.getProperties(event.params.edit);
          const { index } = this.quillRef.getSelection();
          this.insertedSmartTagBlot = this.quillRef.getLeaf(index + 1)[0];
          trackOpenExistingSmartTag({ databaseId: this.databaseId });
          return;
        }

        this.getProperties(event.params.new);
      },
      beforeClose() {
        this.isEditMode = false;
        this.smartTagInstance = null;
        this.label = '';
        this.defaultValue = '';
        this.isCartValueCountdown = false;
        this.quillRef = null;
      },
    },
  };
</script>
<style lang="sass">
  [data-modal='smart-tag-modal']
    .brand-modal-header
      border-bottom: 1px solid #E3E5E8
    .form-label
      font-size: 12px
      color: #8F97A4
    .small-alt-title
      font-family: Roboto
      font-style: normal
      font-weight: normal
      font-size: 12px
      line-height: 24px
      color: #8F97A4
</style>
