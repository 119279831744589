<template lang="pug">
om-input#smartTagInput.w-100(
  @input="update"
  @enter="enter"
  v-model="placeholder"
  type="number"
  ref="defaultValue"
  :label="$t('smartTags.input.cartValueCountdownLabel')"
)
  template(slot="error" v-if="$v.defaultValue.$error && !$v.defaultValue.required")
    span {{ $t('requiredField') }}
</template>
<script>
  import { required, integer } from 'vuelidate/lib/validators';

  export default {
    props: {
      defaultValue: {
        type: String,
      },
      label: {
        type: String,
        default: '',
      },
    },
    validations: {
      defaultValue: {
        required,
        integer,
      },
    },
    data() {
      return {
        placeholder: this.defaultValue || 0,
      };
    },
    methods: {
      isValid() {
        if (!this.defaultValue) return false;

        this.$v.$touch();
        const inputValidation = this.$refs.defaultValue.validate();
        if (this.$v.$invalid || !inputValidation) {
          return false;
        }
      },
      update(value) {
        if (this.isValid()) return;

        this.$emit('update:defaultValue', value);
      },
      enter() {
        this.$emit('enter');
      },
    },
  };
</script>
