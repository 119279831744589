const SmartTagFactory = require('./SmartTagFactory');
const { DATA_ATTRIBUTES, getTagKeyByValue } = require('./helper');

class SmartTagParser {
  static parse(HTMLElement, translator) {
    if (!HTMLElement) return;

    const uid = this._getElementUid(HTMLElement);
    const attribute = this._getElementAttribute(HTMLElement);
    const label = this._getElementLabel(attribute);
    const defaultValue = this._getElementDefaultValue(HTMLElement);

    return new SmartTagFactory({ uid, label, attribute, defaultValue }, translator).instance;
  }

  static buildRawHTML({ uid, attribute, label }) {
    return `<span class="smart-tag" data-smart-tag="${attribute}" ${DATA_ATTRIBUTES.TAG_ID}="${uid}"><span contenteditable="false">${label}</span></span>`;
  }

  static _getElementUid(element) {
    return element.getAttribute(DATA_ATTRIBUTES.TAG_ID);
  }

  static _getElementLabel(attribute) {
    const isCartValueCountdown = attribute.includes('cartValueCountdown');
    let result;
    if (this._hasDefaultValue(attribute)) {
      result = isCartValueCountdown
        ? attribute.split(':')[0].concat(':cart-value-countdown-value').concat(']]')
        : attribute.split('|')[0].concat(']]');
    } else {
      result = isCartValueCountdown
        ? attribute.split(':')[0].concat(':cart-value-countdown-value').concat(']]')
        : attribute;
    }

    return getTagKeyByValue(result);
  }

  static _hasDefaultValue(attribute) {
    return attribute.split('|')[1]?.length > 0;
  }

  static _getElementAttribute(element) {
    return element.getAttribute(DATA_ATTRIBUTES.TAG);
  }

  static _getElementDefaultValue(element) {
    return element.getAttribute(DATA_ATTRIBUTES.TAG).split(']]')[0].split('|')[1];
  }
}

module.exports = SmartTagParser;
