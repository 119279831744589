<template lang="pug">
om-input#smartTagInput.w-100(
  @input="update"
  @enter="enter"
  v-model="placeholder"
  type="text"
  :label="$t('smartTags.input.label', { label })"
)
</template>
<script>
  export default {
    props: {
      defaultValue: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        placeholder: this.defaultValue,
      };
    },
    methods: {
      update(value) {
        this.$emit('update:defaultValue', value);
      },
      enter() {
        this.$emit('enter');
      },
    },
  };
</script>
