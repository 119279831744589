class SmartTag {
  constructor({ uid, label, attribute, defaultValue }) {
    this.uid = uid;
    this.label = label;
    this.attribute = attribute;
    this.defaultValue = defaultValue;
  }

  getUid() {
    return this.uid;
  }

  getLabel() {
    return this.label;
  }

  getAttribute() {
    return this.attribute;
  }

  setAttribute(attribute) {
    this.attribute = attribute;
  }

  getDefaultValue() {
    return this.defaultValue;
  }

  setDefaultValue(value) {
    this._removeDefaultValue();
    this.defaultValue = value;
  }

  replaceDefaultValue(from, to) {
    return this.getAttribute().replace(from, to);
  }
}

module.exports = SmartTag;
