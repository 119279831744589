const SmartTag = require('./SmartTag');

class CartValueCountdown extends SmartTag {
  constructor({ uid, label, attribute, defaultValue }, translator) {
    super({ uid, label, attribute, defaultValue }, translator);
  }

  type() {
    return 'cart-value-countdown';
  }

  isDefaultValueCannotBeSet() {
    return false;
  }

  getContent() {
    return this.defaultValue;
  }

  _removeDefaultValue() {
    const hasDefault = this.hasDefaultValue();
    if (hasDefault) {
      this.setAttribute(this.attribute.split(':')[0].concat(']]'));
    }
  }

  getDefaultValue() {
    this.defaultValue = this.hasDefaultValue();
    return this.defaultValue;
  }

  getAttributeWithDefaultValue() {
    return this.attribute
      .slice(0, this.attribute.length - 2)
      .split(':')[0]
      .concat(`:${this.defaultValue}`)
      .concat(']]');
  }

  hasDefaultValue() {
    const splittedByPipe = this.getAttribute().split(':');
    return splittedByPipe.length > 1 ? splittedByPipe[1].split(']]')[0] : false;
  }

  buildCartValueCountdown(value) {
    const defaultValue = this.hasDefaultValue();
    if (defaultValue) return this.replaceDefaultValue(defaultValue, value);

    return this.getAttribute().replace('cart-value-countdown-value', value);
  }
}

module.exports = CartValueCountdown;
