import { track } from '@/services/xray';

const SMART_TAGS_TRACK_KEY = 'smart-tags';
const LIST_ACTION = 'list-tags';
const SELECT_ACTION = 'select-tags';
const CONFIGURE_DONE_ACTION = 'configure-done';
const CONFIGURE_TAGS_ACTION = 'configure-tags';
const EDIT_CONFIGURE_ACTION = 'edit-configure';
const CANCEL_CONFIGURE_ACTION = 'configure-cancel';

export const trackListing = (type, { databaseId, campaignId, elementId }) => {
  track(SMART_TAGS_TRACK_KEY, {
    action: LIST_ACTION,
    location: 'quill',
    view: `${type}-view`,
    userId: databaseId,
    campaignId,
    elementId,
  });
};

export const trackSelection = (smartTagInstance, { databaseId, elementId }) => {
  track(SMART_TAGS_TRACK_KEY, {
    action: SELECT_ACTION,
    location: 'quill',
    userId: databaseId,
    elementId,
    attribute: smartTagInstance.getAttribute(),
    label: smartTagInstance.getLabel(),
  });
};

export const trackSetDefaultValue = (smartTagInstance, { databaseId, elementId }) => {
  track(SMART_TAGS_TRACK_KEY, {
    action: CONFIGURE_DONE_ACTION,
    location: 'modal',
    userId: databaseId,
    elementId,
    defaultValue: smartTagInstance.getDefaultValue(),
  });
};

export const trackModifyDefaultValue = (smartTagInstance, { databaseId, elementId }) => {
  track(SMART_TAGS_TRACK_KEY, {
    action: EDIT_CONFIGURE_ACTION,
    location: 'modal',
    userId: databaseId,
    elementId,
    defaultValue: smartTagInstance.getDefaultValue(),
  });
};

export const trackCancelButton = ({ databaseId, elementId }) => {
  track(SMART_TAGS_TRACK_KEY, {
    action: CANCEL_CONFIGURE_ACTION,
    location: 'modal',
    userId: databaseId,
    elementId,
  });
};

export const trackOpenExistingSmartTag = ({ databaseId }) => {
  track(SMART_TAGS_TRACK_KEY, {
    action: CONFIGURE_TAGS_ACTION,
    location: 'modal',
    userId: databaseId,
  });
};
