const { General, CartValueCountdown, SmartProductTag } = require('./types');

class SmartTagFactory {
  constructor(...args) {
    const [props, translator] = args;
    this.instance = this.create(props, translator);
  }

  create(props, translator) {
    if (!props) return;

    if (props.attribute.includes('cartValueCountdown')) {
      return new CartValueCountdown(props, translator);
    }

    if (props.attribute.includes('smart_product_tag')) {
      return new SmartProductTag(props);
    }

    return new General(props, translator);
  }
}

module.exports = SmartTagFactory;
